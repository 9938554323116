export default {
    init() {
        let thisElement;
        $("#duration").mask("09999999");
        $('a[data-role="delete"]').on('click',function () {
            event.preventDefault()
            thisElement = $(this)
            Swal.fire({
                title: 'آیا از حذف طرح مطمئن هستید؟',
                text: "آیا میخواهید طرح مورد نظر را حذف کنید؟",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'حذف',
                cancelButtonText: 'لغو'
              }).then((result) => {
                if (result.isConfirmed) {
                    $('#delete-membershiptype-form input[name="membershiptype_id"]').val(thisElement.data('id'))
                    $('#delete-membershiptype-form').submit()
                }
              })
        })
    
    
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
