export default {
    init() {
        let thisElement, previousInnerHtml;

        $.ajaxSetup({
            type: "post",
            data: {
                _token: $("input.js-input-csrf-token").val(),
            },
        });

        $('a[data-role="delete"]').on("click", function () {
            event.preventDefault();
            thisElement = $(this);
            Swal.fire({
                title: "آیا از حذف فایل مطمئن هستید؟",
                text: "آیا میخواهید فایل مورد نظر را حذف کنید؟",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "حذف",
                cancelButtonText: "لغو",
            }).then((result) => {
                if (result.isConfirmed) {
                    $('#delete-file-form input[name="file_id"]').val(
                        thisElement.data("id")
                    );
                    $("#delete-file-form").submit();
                }
            });
        });

        $('td[data-role="change-order"]').on("click", function () {
            previousInnerHtml = $(this).html();
            $(this).html("");
        });

        $('td[data-role="change-order"]').on("blur", function () {
            thisElement = $(this);
            if (/^[0-9]+$/.test(thisElement.html())) {
                $.ajax({
                    url: $("input.js-input-route-file-changeOrder").val(),
                    data: {
                        file_id: thisElement.data("fileId"),
                        order: thisElement.html(),
                    },
                });

                Toast.fire(
                    "عملیات موفق",
                    "ترتیب با موفقیت تغییر کرد",
                    "success"
                );
            } else {
                thisElement.html(previousInnerHtml);
            }
        });
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
