export default {
    init() {
        let thisElement;

        $("#amount").mask("09999999999999", { clearIfNotMatch: true });

        $('a[data-role="delete"]').on("click", function () {
            event.preventDefault();
            thisElement = $(this);
            Swal.fire({
                title: "آیا از حذف مقدار مطمئن هستید؟",
                text: "آیا میخواهید مقدار مورد نظر را حذف کنید؟",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "حذف",
                cancelButtonText: "لغو",
            }).then((result) => {
                if (result.isConfirmed) {
                    $(
                        '#delete-rechargeamount-form input[name="rechargeamount_id"]'
                    ).val(thisElement.data("id"));
                    $("#delete-rechargeamount-form").submit();
                }
            });
        });
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
