export default {
    init() {
        let thisElement, previousInnerHtml, numbersArray, seperatorsNumber;

        $.ajaxSetup({
            type: "post",
            data: {
                _token: $("input.js-input-csrf-token").val(),
            },
        });

        $('#duration').mask('0999999999999999999')

        $("#price").on("keyup", function () {
            numbersArray = $(this).val().trim().replaceAll(",", "").split("");
            seperatorsNumber = 0;
            $.each(numbersArray, function (index) {
                if (index % 3 == 0 && index != 0) {
                    numbersArray.splice(-(index + seperatorsNumber), 0, ",");
                    seperatorsNumber++;
                }
            });
            $(this).val(numbersArray.join(""));
        });

        $('a[data-role="delete"]').on("click", function () {
            event.preventDefault();
            thisElement = $(this);
            Swal.fire({
                title: "آیا از حذف دسته بندی مطمئن هستید؟",
                text: "آیا میخواهید دسته بندی مورد نظر را حذف کنید؟",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "حذف",
                cancelButtonText: "لغو",
            }).then((result) => {
                if (result.isConfirmed) {
                    $('#delete-category-form input[name="category_id"]').val(
                        thisElement.data("id")
                    );
                    $("#delete-category-form").submit();
                }
            });
        });

    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
