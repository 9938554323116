export default {
    init() {
        $("#logout-btn").on("click", function () {
            event.preventDefault();
            Swal.fire({
                title: "خروج",
                text: "آیا برای خروج مطمئن هستید؟",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "خروج",
                cancelButtonText: "بازگشت",
            }).then((result) => {
                if (result.isConfirmed) {
                    $("#logout-form").submit();
                }
            });
        });
    },
    finalize() {},
};
