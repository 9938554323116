export default {
    init() {
        let thisElement, previousInnerHtml;

        $.ajaxSetup({
            type: "post",
            data: {
                _token: $("input.js-input-csrf-token").val(),
            },
        });

        $(".chosen").chosen();

        $('a[data-role="delete"]').on("click", function () {
            event.preventDefault();
            thisElement = $(this);
            Swal.fire({
                title: "آیا از حذف محصول مطمئن هستید؟",
                text: "آیا میخواهید محصول مورد نظر را حذف کنید؟",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "حذف",
                cancelButtonText: "لغو",
            }).then((result) => {
                if (result.isConfirmed) {
                    $('#delete-product-form input[name="product_id"]').val(
                        thisElement.data("id")
                    );
                    $("#delete-product-form").submit();
                }
            });
        });

        $('td[data-role="change-order"]').on("click", function () {
            previousInnerHtml = $(this).html();
            $(this).html("");
        });

        $('td[data-role="change-order"]').on("blur", function () {
            thisElement = $(this);
            if (/^[0-9]+$/.test(thisElement.html())) {
                $.ajax({
                    url: $("input.js-input-route-product-changeOrder").val(),
                    data: {
                        product_id: thisElement.data("productId"),
                        order: thisElement.html(),
                    },
                });

                Toast.fire(
                    "عملیات موفق",
                    "ترتیب با موفقیت تغییر کرد",
                    "success"
                );
            } else {
                thisElement.html(previousInnerHtml);
            }
        });

        $('td[data-role="send-to-all-users"]').on("click", function () {
            event.preventDefault();
            thisElement = $(this);
            Swal.fire({
                title: "ارسال به همه کاربران",
                text: "آیا از ارسال فایل به همه کاربران اطمینان دارید؟",
                icon: "success",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "ارسال",
                cancelButtonText: "لغو",
            }).then((result) => {
                if (result.isConfirmed) {
                    $.ajax({
                        url: $(
                            "input.js-input-route-product-sendToAllUsers"
                        ).val(),
                        data: {
                            product_id: thisElement.data("productId"),
                        },
                    });

                    Toast.fire(
                        "عملیات موفق",
                        "فایل با موفقیت به کاربران ارسال شد",
                        "success"
                    );
                }
            });
        });
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
