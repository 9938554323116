import 'bootstrap/dist/js/bootstrap.bundle';
require('./timepicker');
// require('./jquery-timepicker');
require('./persiandate.js');
require('./chart.js');
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
defineLordIconElement(loadAnimation); 

// import {jalali_to_gregorian , gregorian_to_jalali} from './dateconvert.js'
// global.jalali_to_gregorian = jalali_to_gregorian
// global.gregorian_to_jalali = gregorian_to_jalali 