export default {
    init() {
        let numbersArray, seperatorsNumber;

        $('#duration').mask('0999999999999999999')

        $("#price").on("keyup", function () {
            numbersArray = $(this).val().trim().replaceAll(",", "").split("");
            seperatorsNumber = 0;
            $.each(numbersArray, function (index) {
                if (index % 3 == 0 && index != 0) {
                    numbersArray.splice(-(index + seperatorsNumber), 0, ",");
                    seperatorsNumber++;
                }
            });
            $(this).val(numbersArray.join(""));
        });

    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
