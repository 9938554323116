export default {
    init() {
        let numbersArray, seperatorsNumber;

        $("#bank_card_number").mask("0000-0000-0000-0000", {
            clearIfNotMatch: true,
        });

        $(
            "#price_per_gigs_first_server,#price_per_gigs_second_server,#sell_server_max_traffic_first_server,#sell_server_max_traffic_second_server,#test_server_max_traffic_first_server,#test_server_max_traffic_second_server,#gift_amount_per_buy"
        ).on("keyup", function () {
            numbersArray = $(this).val().trim().replaceAll(",", "").split("");
            seperatorsNumber = 0;
            $.each(numbersArray, function (index) {
                if (index % 3 == 0 && index != 0) {
                    numbersArray.splice(-(index + seperatorsNumber), 0, ",");
                    seperatorsNumber++;
                }
            });
            $(this).val(numbersArray.join(""));
        });
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
