global.$ = global.jQuery = require("jquery");
require("./vendors/sweetalert");
require("./components/components");
require("chosen-js");
require("chosen-js/chosen.css");
require("./vendors/vendors");
require("./variables/variables");
require("./vendors/inputmask-jquery");


let url = $(location).attr("href"),
    parts,
    last_part;
parts = url.split("?")[0].split("/");

$(function () {
    if (parts.includes("edit") || parts.includes("update")) {
        last_part = `edit${parts[parts.length - 3]}`;
    } else if (parts.includes("show")) {
        last_part = `show${parts[parts.length - 3]}`;
    } else {
        last_part = parts[parts.length - 1];
    }

    $("body").addClass(last_part);
});

// import local dependencies
import Router from "./util/Router";
import common from "./routes/common";
import telegrambotuser from "./partials/telegrambotuser/index";
import membershiptype from "./partials/membershiptype/index";
import editmembershiptype from "./partials/membershiptype/edit";
import setting from "./partials/setting/index";
import file from "./partials/file/index";
import category from "./partials/category/index";
import editcategory from "./partials/category/edit";
import product from "./partials/product/index";
import editproduct from "./partials/product/edit";
import rechargeamount from "./partials/rechargeamount/index";


/** Populate Router instance with DOM routes */
const routes = new Router({
    // All pages
    common,
    telegrambotuser,
    setting,
    category,
    editcategory,
    membershiptype,
    editmembershiptype
});

// Load Events
// $(document).ready(() => routes.loadEvents());
$(() => routes.loadEvents());
