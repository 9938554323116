$(function () {
    var themeAttr = localStorage.getItem('data-theme');
    const checkbox = document.getElementById("switch");

    document.documentElement.setAttribute("data-theme", themeAttr)

    if (themeAttr == 'dark') {
        checkbox.checked = true;
    }

    let theme = localStorage.getItem('data-theme');
    const changeThemeToDark = () => {
        document.documentElement.setAttribute("data-theme", "dark")
        localStorage.setItem("data-theme", "dark")
    }

    const changeThemeToLight = () => {
        document.documentElement.setAttribute("data-theme", "light")
        localStorage.setItem("data-theme", 'light')
    }


    if (checkbox) {
        checkbox.addEventListener('change', () => {
            let theme = localStorage.getItem('data-theme');
            if (theme === 'dark') {
                changeThemeToLight()
            } else {
                changeThemeToDark()
            }
        });

    }

})